<template>
    <div class="border border-gray-400 rounded-3xl sm:grid sm:grid-cols-4 sm:gap-4 p-4">
        <account-setting-menu></account-setting-menu>
        <router-view class="col-span-3"></router-view>
    </div>
</template>

<script>
    import AccountSettingMenu from '@/components/account/SettingMenu.vue';

    export default {
        name: 'AccountSetting',

        components: {
            AccountSettingMenu
        }
    };
</script>
