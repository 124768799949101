<template>
    <div class="border border-gray-200 rounded-2xl shadow-lg py-4 mb-4 sm:mb-0">
        <input v-show="false" ref="fileImage" type="file" accept="image/*" @change="updateProfileImage" />
        <div v-show="false" id="base64Image"></div>
        <div class="w-28 h-2w-28 mx-auto relative" @click="$refs.fileImage.click()">
            <font-awesome-icon
                icon="fa-solid fa-camera"
                class="bg-primary hover:bg-primary-darker cursor-pointer text-white h-4 w-4 p-2 border-2 border-white rounded-full absolute right-0"
            />
            <img class="rounded-full w-full border border-gray-200" :src="$store.state.user.foto" />
        </div>
        <div class="font-extrabold my-4 text-xl text-center">{{ $store.state.user.nama }}</div>
        <div class="px-8 font-bold">
            <div class="py-3 border-t-2 border-gray-200 text-center sm:text-left" :class="{ 'text-primary': isActive('AccountProfile') }">
                <router-link :to="{ name: 'AccountProfile' }">Account</router-link>
            </div>
            <div class="py-3 border-t-2 border-gray-200 text-center sm:text-left" :class="{ 'text-primary': isActive('AccountPassword') }">
                <router-link :to="{ name: 'AccountPassword' }">Password</router-link>
            </div>
            <!-- <div class="py-3 border-t-2 border-gray-200 text-center sm:text-left" :class="{ 'text-primary': isActive('AccountPin') }">
                <router-link :to="{ name: 'AccountPin' }">PIN TripwePay</router-link>
            </div> -->
            <div
                class="py-3 border-t-2 border-b-2 border-gray-200 text-center sm:text-left"
                :class="{ 'text-primary': isActive('AccountAddress') }"
            >
                <router-link :to="{ name: 'AccountAddressIndex' }">My Address</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountSettingMenu',

        methods: {
            isActive(name) {
                return this.$route.matched[2].name == name;
            },
            async updateProfileImage(event) {
                if (event.target.files[0]) {
                    this.$store.commit('setOverlayLoading', true);

                    const reader = new FileReader();

                    reader.onload = function () {
                        document.getElementById('base64Image').innerHTML = reader.result
                            .replace('data:', '')
                            .replace(/^.+,/, '');
                    };

                    reader.readAsDataURL(event.target.files[0]);

                    setTimeout(async () => {
                        try {
                            const response = await this.$http.post(
                                `${this.$apiTripweWeb}/user/photo/update`,
                                {
                                    foto: document.getElementById('base64Image').innerText,
                                }
                            );

                            this.$store.commit(
                                'setUser',
                                Object.assign({}, this.$store.state.user, {
                                    foto: response.data.data.foto
                                })
                            );

                            this.$toasted.global.success('Your profile has been updated');
                        } catch (error) {
                            this.$toasted.global.error(error);
                        }

                        this.$store.commit('setOverlayLoading', false);
                    }, 500);
                }
            }
        }
    };
</script>
